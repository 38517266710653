import { BoxGeometry, BufferGeometry, CatmullRomCurve3, CubeCamera, Group, ImageUtils, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, MeshPhongMaterial, Object3D, PerspectiveCamera, TextureLoader, TubeBufferGeometry, Vector2, Vector3 } from "three"
import { Util } from "../util"
import { Vec2 } from "./vec2"
import { RoadBufferGeometry } from "./roadbuffergeometry"
import { TrackGenerator } from "./trackgenerator"

export interface TrackDefinition {
    points: Vec2[];
}


export class Track {

    static items: Object3D[] = []

    static async initItemsIfNecessary() {
        if (Track.items.length > 0)
            return
        let paths = [
            "tree_blocks.glb", 
            "tree_cone.glb", 
            "tree_plateau.glb", 
            "tree_oak.glb",
            "stone_tallA.glb",
            "stone_tallB.glb",
            "stone_tallC.glb",
        ]
        for (let path of paths) {
            let o = await Util.loadGLTF('/models/' + path)
            Track.items.push(o)
        } 

    }

    points: Vec2[] = []
    addedToScene = false
    group!: Group
    curveObject!: Line
    mesh!: Mesh
    points3d: Vector3[] = []
    geometry!: RoadBufferGeometry

    

    constructor(definition: TrackDefinition) {
        this.points.push(...definition.points)
    }

    static minimumDistance(points: Vector3[], p: Vector3) {
        let distance = 10E10
        for (let point of points) {
            distance = Math.min(point.distanceTo(p), distance)
        }
        return distance
    }

    async emitToScene(parent: Object3D): Promise<any> {
        if (this.addedToScene) {
            return
        }

        await Track.initItemsIfNecessary()

        this.group = new Group()
        this.addedToScene = true
        for (let i = 0, n = this.points.length; i < n; i++) {
            const p = this.points[i % n]
            const scale = 2
            this.points3d.push(new Vector3(p.x * scale, 15, p.y * scale))
        }

        //const texture = new TextureLoader().load('/img/textures/checkers_colored.png')
        const texture = new TextureLoader().load('/img/textures/road.png')
        const curve = new CatmullRomCurve3(this.points3d, true)
        let material
        material = new MeshBasicMaterial({ color: 0xff4040 });
        material = new MeshBasicMaterial({ transparent: false, map: texture });
        material.wireframe = !true
        const extrusionSegments = 200
        const radiusSegments = 2
        //geometry = new TubeBufferGeometry(curve, extrusionSegments, 24, radiusSegments, true)
        this.geometry = new RoadBufferGeometry(curve, extrusionSegments, 20, radiusSegments, true)
        this.mesh = new Mesh(this.geometry, material)
        this.group.add(this.mesh)

        const points = curve.getPoints(100)
        for (let i = 0, n = points.length; i < n; i++) {
            const a = points[i]
            const b = points[(i + 1) % n]

            const v = new Vector3(b.x - a.x, b.y - a.y, b.z - a.z)
            const norm = new Vector3(0, 1, 0)
            const dir = v.clone()
            dir.cross(norm)
            dir.normalize()

            const outerpoint1 = a.clone()
            outerpoint1.addScaledVector(dir, 50)
            let dist1 = Track.minimumDistance(points, outerpoint1)
            //console.log("dist1: " + dist1)

            const outerpoint2 = a.clone()
            outerpoint2.addScaledVector(dir, -50)
            let dist2 = Track.minimumDistance(points, outerpoint2)
            //console.log("dist2: " + dist2)

            const TOO_CLOSE = 10
            if (dist1 < TOO_CLOSE || dist2 < TOO_CLOSE) {
                continue
            }

            let treeSize = 25
            {
                let item = Util.randomElement(Track.items)
                item = item.clone()
                item.scale.set(treeSize, treeSize, treeSize)
                item.position.copy(outerpoint1)
                this.group.add(item);
            }
            {
                let item = Util.randomElement(Track.items)
                item = item.clone()
                item.scale.set(treeSize, treeSize, treeSize)
                item.position.copy(outerpoint2)
                this.group.add(item);
            }

        }
        parent.add(this.group)
    }

    removeFromScene(parent: Object3D) {
        if (!this.addedToScene) {
            return
        }
        this.addedToScene = false
        parent.remove(this.group)
        if (this.curveObject) {
            this.curveObject.geometry.dispose()
        }
        if (this.mesh) {
            this.mesh.geometry.dispose()
        }
        this.group = null
    }

    applyToCamera(t: number, camera: PerspectiveCamera) {

        if (!this.geometry) {
            return
        }
        
        //console.log("t=" + t)
        const curve = new CatmullRomCurve3(this.points3d, true)
        const position = new Vector3()
        this.geometry.path.getPointAt(t, position); curve.getPointAt(t, position)
        position.multiplyScalar(1);

        // interpolation
        const segments = this.geometry.tangents.length;
        const pickt = t * segments;
        const pick = Math.floor(pickt);
        const pickNext = (pick + 1) % segments;

        const binormal = new Vector3()
        binormal.subVectors(this.geometry.binormals[pickNext], this.geometry.binormals[pick]);
        binormal.multiplyScalar(pickt - pick).add(this.geometry.binormals[pick]);

        const direction = new Vector3()
        this.geometry.path.getTangentAt(t, direction);
        const height = 8;
        const normal = new Vector3()
        normal.copy(binormal).cross(direction);
        normal.negate()
        position.add(normal.clone().multiplyScalar(height));
        camera.position.copy(position);
        const lookAt = new Vector3()
        this.geometry.path.getPointAt((t + 30 / this.geometry.path.getLength()) % 1, lookAt);
        lookAt.multiplyScalar(1);
        const lookAhead = !false
        if (!lookAhead) {
            lookAt.copy(position).add(direction);
        }
        camera.matrix.lookAt(camera.position, lookAt, normal);
        camera.quaternion.setFromRotationMatrix(camera.matrix);
    }

}