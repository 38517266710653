/*
 * (c) 2020, Stepan Rutz / stepan.rutz AT gmx.de
 */
import Vue from 'vue';
import { Scene } from 'three';
import { Track, TrackDefinition } from './track/track';
import { TrackGenerator } from './track/trackgenerator';
import { Util } from './util';



export enum GameState { STOPPED, STARTED }

/**
 * Main class, contains all game state.
 */

export class Game {
    private eventBus = new Vue()
    private state = GameState.STOPPED
    private scene: Scene
    private track!: Track

    constructor(scene: Scene) {
        this.scene = scene
    }

    getTrack() {
        return this.track
    }

    async init(): Promise<any> {
        await this.nextTrack(false)
    }

    async nextTrack(random: boolean) {
        if (this.track) {
            this.track.removeFromScene(this.scene)
        }
        let definition: TrackDefinition
        if (random) {
            let generator = new TrackGenerator()
            let points = generator.generate()
            definition = {
                points: points
            }
        } else {
            definition = await Util.loadJson("/track1.json")
        }
        this.track = new Track(definition)
        await this.track.emitToScene(this.scene)
    }

    on(event: string | string[], callback: Function) {
        this.eventBus.$on(event, callback)
        return this
    }

    off(event?: string | string[], callback?: Function) {
        this.eventBus.$off(event, callback)
        return this
    }

    getState() {
        return this.state
    }

    private setState(state: GameState) {
        if (this.state == state)
            return
        this.state = state
        this.eventBus.$emit("state", this.state)
    }

    start() {
        if (this.state == GameState.STARTED)
            return
        this.setState(GameState.STARTED)
    }

    stop() {
        if (this.state == GameState.STOPPED)
            return
        this.setState(GameState.STOPPED)
    }

    dump() {
        console.log(JSON.stringify(this.track.points, null, 4))
        console.dir(this)
    }


}
