import Vue from 'vue'
import App from './App.vue'

//import './registerServiceWorker'
import router from './router'
import store from './store'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import './styles.scss'

import Sidebar from './components/sidebar.vue'
import Navbar from './components/navbar.vue'
import GameArea from './components/gamearea.vue'

Vue.config.productionTip = false
Vue.use(Buefy)
Vue.component('sidebar', Sidebar)
Vue.component('navbar', Navbar)
Vue.component('gamearea', GameArea)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
