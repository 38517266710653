







































import { StateType } from '@/store';
import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class About extends Vue {

    active = true

}
