



























import { StateType } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import GameArea from './gamearea.vue';

@Component
export default class Navbar extends Vue {
    onToggleMenu() {
        const store = this.$store as Store<StateType>;
        const state = store.state;
        store.commit("sidebarShown", !state.sidebarShown);
    }

    onStart() {
        const store = this.$store as Store<StateType>;
        store.commit('gameStarted', true)
        if (this.$router.currentRoute.path != '/')
            this.$router.replace("/")
    }

    onStop() {
        const store = this.$store as Store<StateType>;
        store.commit('gameStarted', false)
        if (this.$router.currentRoute.path != '/')
           this.$router.replace("/")
    }

    onNextTrack() {
        let game = GameArea.instance.getGame()
        game.nextTrack(true)
        if (this.$router.currentRoute.path != '/')
           this.$router.replace("/")
    }

    @Watch("$store.state.gameStarted")
    gameStartedChanged() {
    }

}
