/**
 * @author sole / http://soledadpenades.com
 * @author mrdoob / http://mrdoob.com
 * @author Robert Eisele / http://www.xarg.org
 * @author Philippe / http://philippe.elsass.me
 * @author Robert Penner / http://www.robertpenner.com/easing_terms_of_use.html
 * @author Paul Lewis / http://www.aerotwist.com/
 * @author lechecacharro
 * @author Josh Faul / http://jocafa.com/
 * @author egraether / http://egraether.com/
 */

// This is a manual port to native typescript of tween.js
// based on the above and from 
//   https://www.npmjs.com/package/@types/tweenjs
//
// ported by stepan.rutz@gmx.de
// original license/code not changed, just ported to ts

if (Date.now === undefined) {
    Date.now = function () {
        return new Date().valueOf();
    }
}


export let Interpolation = {

    Linear: function (v: number[], k: number) {

        let m = v.length - 1, f = m * k, i = Math.floor(f), fn = Utils.Linear;

        if (k < 0) return fn(v[0], v[1], f);
        if (k > 1) return fn(v[m], v[m - 1], m - f);

        return fn(v[i], v[i + 1 > m ? m : i + 1], f - i);

    },

    Bezier: function (v: number[], k: number) {

        let b = 0, n = v.length - 1, pw = Math.pow, bn = Utils.Bernstein, i;

        for (i = 0; i <= n; i++) {
            b += pw(1 - k, n - i) * pw(k, i) * v[i] * bn(n, i);
        }

        return b;

    },

    CatmullRom: function (v: number[], k: number) {

        let m = v.length - 1, f = m * k, i = Math.floor(f), fn = Utils.CatmullRom;

        if (v[0] === v[m]) {

            if (k < 0) i = Math.floor(f = m * (1 + k));

            return fn(v[(i - 1 + m) % m], v[i], v[(i + 1) % m], v[(i + 2) % m], f - i);

        } else {

            if (k < 0) return v[0] - (fn(v[0], v[0], v[1], v[1], -f) - v[0]);
            if (k > 1) return v[m] - (fn(v[m], v[m], v[m - 1], v[m - 1], f - m) - v[m]);

            return fn(v[i ? i - 1 : 0], v[i], v[m < i + 1 ? m : i + 1], v[m < i + 2 ? m : i + 2], f - i);

        }

    }
}

let Utils = {

    Linear: function (p0: number, p1: number, t: number) {

        return (p1 - p0) * t + p0;

    },

    Bernstein: function (n: number, i: number) {
        let factorial = function () {
            let a = [1];

            return function (n: number): number {

                let s = 1, i;
                if (a[n]) return a[n];
                for (i = n; i > 1; i--) s *= i;
                return a[n] = s;

            };
        }()
        return factorial(n) / factorial(i) / factorial(n - i);

    },

    CatmullRom: function (p0: number, p1: number, p2: number, p3: number, t: number) {

        let v0 = (p2 - p0) * 0.5, v1 = (p3 - p1) * 0.5, t2 = t * t, t3 = t * t2;
        return (2 * p1 - 2 * p2 + v0 + v1) * t3 + (- 3 * p1 + 3 * p2 - 2 * v0 - v1) * t2 + v0 * t + p1;

    }

}


export let Easing = {

    Linear: {

        None: function (k: number): number {

            return k;

        }

    },

    Quadratic: {

        In: function (k: number): number {

            return k * k;

        },

        Out: function (k: number): number {

            return k * (2 - k);

        },

        InOut: function (k: number): number {

            if ((k *= 2) < 1) return 0.5 * k * k;
            return - 0.5 * (--k * (k - 2) - 1);

        }

    },

    Cubic: {

        In: function (k: number): number {

            return k * k * k;

        },

        Out: function (k: number): number {

            return --k * k * k + 1;

        },

        InOut: function (k: number): number {

            if ((k *= 2) < 1) return 0.5 * k * k * k;
            return 0.5 * ((k -= 2) * k * k + 2);

        }

    },

    Quartic: {

        In: function (k: number): number {

            return k * k * k * k;

        },

        Out: function (k: number): number {

            return 1 - (--k * k * k * k);

        },

        InOut: function (k: number): number {

            if ((k *= 2) < 1) return 0.5 * k * k * k * k;
            return - 0.5 * ((k -= 2) * k * k * k - 2);

        }

    },

    Quintic: {

        In: function (k: number): number {

            return k * k * k * k * k;

        },

        Out: function (k: number): number {

            return --k * k * k * k * k + 1;

        },

        InOut: function (k: number): number {

            if ((k *= 2) < 1) return 0.5 * k * k * k * k * k;
            return 0.5 * ((k -= 2) * k * k * k * k + 2);

        }

    },

    Sinusoidal: {

        In: function (k: number): number {

            return 1 - Math.cos(k * Math.PI / 2);

        },

        Out: function (k: number): number {

            return Math.sin(k * Math.PI / 2);

        },

        InOut: function (k: number): number {

            return 0.5 * (1 - Math.cos(Math.PI * k));

        }

    },

    Exponential: {

        In: function (k: number): number {

            return k === 0 ? 0 : Math.pow(1024, k - 1);

        },

        Out: function (k: number): number {

            return k === 1 ? 1 : 1 - Math.pow(2, - 10 * k);

        },

        InOut: function (k: number): number {

            if (k === 0) return 0;
            if (k === 1) return 1;
            if ((k *= 2) < 1) return 0.5 * Math.pow(1024, k - 1);
            return 0.5 * (- Math.pow(2, - 10 * (k - 1)) + 2);

        }

    },

    Circular: {

        In: function (k: number): number {

            return 1 - Math.sqrt(1 - k * k);

        },

        Out: function (k: number): number {

            return Math.sqrt(1 - (--k * k));

        },

        InOut: function (k: number): number {

            if ((k *= 2) < 1) return - 0.5 * (Math.sqrt(1 - k * k) - 1);
            return 0.5 * (Math.sqrt(1 - (k -= 2) * k) + 1);

        }

    },

    Elastic: {

        In: function (k: number): number {

            let s, a = 0.1, p = 0.4;
            if (k === 0) return 0;
            if (k === 1) return 1;
            if (!a || a < 1) { a = 1; s = p / 4; }
            else s = p * Math.asin(1 / a) / (2 * Math.PI);
            return - (a * Math.pow(2, 10 * (k -= 1)) * Math.sin((k - s) * (2 * Math.PI) / p));

        },

        Out: function (k: number): number {

            let s, a = 0.1, p = 0.4;
            if (k === 0) return 0;
            if (k === 1) return 1;
            if (!a || a < 1) { a = 1; s = p / 4; }
            else s = p * Math.asin(1 / a) / (2 * Math.PI);
            return (a * Math.pow(2, - 10 * k) * Math.sin((k - s) * (2 * Math.PI) / p) + 1);

        },

        InOut: function (k: number): number {

            let s, a = 0.1, p = 0.4;
            if (k === 0) return 0;
            if (k === 1) return 1;
            if (!a || a < 1) { a = 1; s = p / 4; }
            else s = p * Math.asin(1 / a) / (2 * Math.PI);
            if ((k *= 2) < 1) return - 0.5 * (a * Math.pow(2, 10 * (k -= 1)) * Math.sin((k - s) * (2 * Math.PI) / p));
            return a * Math.pow(2, -10 * (k -= 1)) * Math.sin((k - s) * (2 * Math.PI) / p) * 0.5 + 1;

        }

    },

    Back: {

        In: function (k: number): number {

            let s = 1.70158;
            return k * k * ((s + 1) * k - s);

        },

        Out: function (k: number): number {

            let s = 1.70158;
            return --k * k * ((s + 1) * k + s) + 1;

        },

        InOut: function (k: number): number {

            let s = 1.70158 * 1.525;
            if ((k *= 2) < 1) return 0.5 * (k * k * ((s + 1) * k - s));
            return 0.5 * ((k -= 2) * k * ((s + 1) * k + s) + 2);

        }

    },

    Bounce: {

        In: function (k: number): number {

            return 1 - Easing.Bounce.Out(1 - k);

        },

        Out: function (k: number): number {

            if (k < (1 / 2.75)) {

                return 7.5625 * k * k;

            } else if (k < (2 / 2.75)) {

                return 7.5625 * (k -= (1.5 / 2.75)) * k + 0.75;

            } else if (k < (2.5 / 2.75)) {

                return 7.5625 * (k -= (2.25 / 2.75)) * k + 0.9375;

            } else {

                return 7.5625 * (k -= (2.625 / 2.75)) * k + 0.984375;

            }

        },

        InOut: function (k: number): number {

            if (k < 0.5) return Easing.Bounce.In(k * 2) * 0.5;
            return Easing.Bounce.Out(k * 2 - 1) * 0.5 + 0.5;

        }
    }
}



export class Group {
    private _tweens: Tween[] = [];

    getAll() {
        return this._tweens;
    }

    removeAll() {
        this._tweens = [];
    }

    add(tween: Tween) {
        this._tweens.push(tween);
        //this.dumptweens();
    }

    remove(tween: Tween) {
        if (tween._debug) {
            tween.debuglog("remove")
        }
        let i = this._tweens.indexOf(tween);
        if (i !== -1) {
            this._tweens.splice(i, 1);
        }
        //this.dumptweens();
    }

    update(time?: number) {
        if (this._tweens.length === 0) return false;
        time = time !== undefined ? time : Date.now();
        let i = 0, numTweens = this._tweens.length;
        while (i < numTweens) {
            if (this._tweens[i]?.update(time)) {
                i++;
            } else {
                //console.log("splicing tween " + this._tweens[i].getName())
                this._tweens.splice(i, 1);
                numTweens--;
            }
        }
        return true;
    }

    dumptweens() {
        let s = ""
        for (let i = 0, n = this._tweens.length; i < n; i++) {
            let tween = this._tweens[i]
            if (s.length > 0)
                s += ","
            s += tween.getName() + "(" + tween._id + ")"
        }
        console.log("tweens: "  + s)
    }
}



export class Tween {

    static instanceCounter = 1000

    public _id: number;
    private _object: any;
    private _name: any;
    private _valuesStart: any = {};
    private _valuesEnd: any = {};
    private _duration = 1000;
    private _delayTime = 0;
    private _startTime!: number;
    private _easingFunction = Easing.Linear.None;
    private _interpolationFunction = Interpolation.Linear;
    private _chainedTweens: Tween[] = [];
    private _onStartCallback!: (object: any) => void ;
    private _onStartCallbackFired = false;
    private _onUpdateCallback!: (object: any, elapsed: number) => void;
    private _onCompleteCallback!: (object: any) => void;
    public _debug = false

    constructor(object: any, name?: string) {
        this._object = object
        this._name = name
        this._id = Tween.instanceCounter++
    }

    getName() {
        return this._name
    }

    to(properties: any, duration: number) {
        if (this._duration !== undefined) {
            this._duration = duration;
        }
        this._valuesEnd = properties;
        return this;
    }

    start(time?: number) {
        if (this._debug) {
            this.debuglog("start")
        }
        TWEEN.add(this);
        this._onStartCallbackFired = false;
        this._startTime = time !== undefined ? time : Date.now();
        this._startTime += this._delayTime;
        for (let property in this._valuesEnd) {
            // This prevents the interpolation of null values or of non-existing properties
            if (this._object[property] === null || !(property in this._object)) {
                continue;
            }
            // check if an Array was provided as property value
            if (this._valuesEnd[property] instanceof Array) {
                if (this._valuesEnd[property].length === 0) {
                    continue;
                }
                // create a local copy of the Array with the start value at the front
                this._valuesEnd[property] = [this._object[property]].concat(this._valuesEnd[property]);
            }
            this._valuesStart[property] = this._object[property];
        }
        return this;
    }

    stop() {
        if (this._debug) {
            this.debuglog("stop")
        }
        TWEEN.remove(this);
        return this;
    }

    delay(amount: number) {
        this._delayTime = amount;
        return this;
    }

    easing(easing: (k: number) => number) {
        this._easingFunction = easing;
        return this;
    }

    interpolation(interpolation: (v: number[], k: number) => number) {
        this._interpolationFunction = interpolation;
        return this;
    }

    chain(...tweens: Tween[]) {
        this._chainedTweens = tweens;
        return this;
    }

    onStart(callback: (object: any) => void) {
        this._onStartCallback = callback;
        return this;
    }

    onUpdate(callback: (object: any, time: number) => void) {
        this._onUpdateCallback = callback;
        return this;
    }

    onComplete(callback: (object: any) => void) {
        this._onCompleteCallback = callback;
        return this;
    }


    update(time: number): boolean {
        if (this._debug) {
            //this.debuglog("update: time=" + time + ", startTime=" + this._startTime) 
        }
        if (time < this._startTime) {
            return true;
        }

        if (this._onStartCallbackFired === false) {
            if (this._onStartCallback) {
                this._onStartCallback.call(this, this._object);
            }
            this._onStartCallbackFired = true;
        }

        let elapsed = (time - this._startTime) / this._duration;
        elapsed = elapsed > 1 ? 1 : elapsed;

        if (this._debug) {
            this.debuglog("update: elapsed=" + elapsed)
        }

        let value = this._easingFunction(elapsed);
        for (let property in this._valuesStart) {
            let start = this._valuesStart[property];
            let end = this._valuesEnd[property];
            if (end instanceof Array) {
                this._object[property] = this._interpolationFunction(end, value);
            } else {
                this._object[property] = start + (end - start) * value;
            }
        }

        if (this._onUpdateCallback) {
            this._onUpdateCallback(this._object, value);
        }

        if (elapsed == 1) {
            if (this._onCompleteCallback) {
                this._onCompleteCallback.call(this, this._object);
            } else {
                this.debuglog("ending tween without endcallback");
            }
            for (let i = 0, numChainedTweens = this._chainedTweens.length; i < numChainedTweens; i++) {
                this._chainedTweens[i].start(time);
            }
            if (this._debug) {
                this.debuglog("update-remove, elapsed=" + elapsed);
            }
            return false;
        }
        return true;
    }

    debuglog(s: string) {
        console.log(`${this._id}: ${this._name}: ${s}`)
    }
}

export let TWEEN = new Group()
