import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Gamearea from '../components/gamearea.vue'
import About from '../components/about.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Gamearea
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
