import { BoxGeometry, BufferGeometry, Color, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, Object3D, Vector3 } from "three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export class Util {

    static async loadString(url: string): Promise<string> {
        const response = await fetch(url)
        const data = await response.text()
        return data
    }

    static async loadJson(url: string): Promise<any> {
        const response = await fetch(url)
        const data = await response.json()
        return data
    }

    static rad2deg(rad: number) {
        return rad / Math.PI * 180
    }

    static deg2rad(deg: number) {
        return deg / 180 * Math.PI
    }

    /* random number < n */
    static random(n: number): number {
        return Math.floor(Math.random() * n);
    }

    static random2(a: number, b: number) {
        return a + Util.random(b - a)
    }

    static randomElement<T>(array: T[]) {
        return array[Util.random(array.length)]
    }

    static async loadOBJ(path: string) {
        return new Promise<Object3D>((resolve, reject) => {
            const loader = new OBJLoader();
            loader.load(
                path,
                function (object) {
                    resolve(object)
                },
                function (xhr) {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                },
                function (error) {
                    reject(error)
                }
            );
        })
    }

    static async loadGLTF(path: string) {
        return new Promise<Object3D>((resolve, reject) => {
            const loader = new GLTFLoader();
            loader.load(
                path,
                function (object) {
                    const scene = object.scene
                    resolve(scene)
                },
                function (xhr) {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                },
                function (error) {
                    reject(error)
                }
            );
        })
    }

    static toStringVector3(v: Vector3) {
        return `[ x=${v.x.toFixed(4)}, y=${v.y.toFixed(4)}, z=${v.z.toFixed(4)} ]`
    }

    static coloredLine(a: Vector3, b: Vector3, color: number) {
        const points = [];
        points.push(a.clone())
        points.push(b.clone())
        const geometry = new BufferGeometry().setFromPoints(points);
        const material = new LineBasicMaterial({ color : color })
        const line = new Line(geometry, material)
        return line
    }

    static coloredCube(size: number) {
        const geometry = new BoxGeometry(size, size, size)
        const colors = [
            0xff0000,
            0x00ff00,
            0x0000ff,
            0xff00ff,
            0x00ffff,
            0xffff00,
        ]
        for (let i = 0; i < geometry.faces.length; i++) {
            const face = geometry.faces[i]
            face.materialIndex = i
        }
        
        const materials: MeshBasicMaterial[] = []
        for (const color of colors) {
            const m = new MeshBasicMaterial( { color: new Color(color) } )
            materials.push(m)
            materials.push(m)
        }
        const mesh = new Mesh(geometry, materials)
        return mesh

    }

}