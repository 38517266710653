import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export const state = {
    sidebarShown: false,
    gameStarted: false,
}

export type StateType = typeof state



export default new Vuex.Store({
    state,
    mutations: {
        sidebarShown(state, sidebarShown: boolean) {
            if (state.sidebarShown == sidebarShown)
                return
            state.sidebarShown = sidebarShown
        },
        gameStarted(state, gameStarted: boolean) {
            if (state.gameStarted == gameStarted)
                return
            state.gameStarted = gameStarted
        }
    },
    actions: {
    },
    modules: {
    }
})