

















import { StateType } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from 'vuex';

@Component
export default class Sidebar extends Vue {
    open = false;

    @Watch("$store.state.sidebarShown")
    sidebarShownChanged() {
        const state = this.$store.state as StateType;
        this.open = state.sidebarShown
        //if (state.sidebarShown) this.open = true;
    }
    @Watch("open") openChanged() {
        const state = this.$store.state as StateType;
        if (!this.open) state.sidebarShown = false;
    }

    close() {
        const store = this.$store as Store<StateType>;
        store.commit("sidebarShown", false);
    }

    onGame() {
        this.close()
        if (this.$router.currentRoute.path != '/')
            this.$router.push('/')
    }

    onAbout() {
        this.close()
        if (this.$router.currentRoute.path != '/about')
            this.$router.push('/about')
    }
}
