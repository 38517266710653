import { Util } from "../util";
import { Vec2 } from "./vec2";

export class TrackGenerator {
    generate() {

        const xmin = 20
        const xmax = 700
        const ymin = 20
        const ymax = 500

        let n = Util.random2(10, 15)
        let randPoints: Vec2[] = []
        for (let i = 0; i < n; i++) {
            let x = Util.random2(xmin, xmax)
            let y = Util.random2(ymin, ymax)
            randPoints.push(new Vec2(x, y))
        }
        for (let i = 0, n = randPoints.length; i < n; i++) {
            let p = randPoints[i]
            console.log("  rnd " + p)
        }

        let points = TrackGenerator.hull(randPoints)
        for (let i = 0, n = points.length; i < n; i++) {
            let p = points[i]
            console.log("  hull " + p)
        }
        points = TrackGenerator.stabilizePoints(points, 3)
        for (let i = 0, n = points.length; i < n; i++) {
            let p = points[i]
            console.log("  stable1 " + p)
        }
        points = TrackGenerator.increaseCurves(points)
        points = TrackGenerator.fixAnglesAndDistances(points)
        points = TrackGenerator.center(points)

        for (let i = 0, n = points.length; i < n; i++) {
            let p = points[i]
            console.log("  final " + p)
        }
        return points
    }


    static fixAnglesAndDistances(points: Vec2[]) {
        for (let i = 0; i < 5; ++i) {
            points = TrackGenerator.fixAngles(points);
            points = TrackGenerator.widen(points, 40);
        }
        return points;
    }

    static fixAngles(points: Vec2[]) {
        for (let i = 0; i < points.length; ++i) {
            let previous = (i - 1 < 0) ? points.length - 1 : i - 1;
            let next = (i + 1) % points.length;
            let px = points[i].x - points[previous].x;
            let py = points[i].y - points[previous].y;
            let pl = Math.sqrt(px * px + py * py);
            px = px / pl;
            py = py / pl;

            let nx = points[i].x - points[next].x;
            let ny = points[i].y - points[next].y;
            nx = -nx;
            ny = -ny;
            let nl = Math.sqrt(nx * nx + ny * ny);
            nx = nx / nl;
            ny = ny / nl;

            let a = Math.atan2(px * ny - py * nx, px * nx + py * ny);
            if (Math.abs(a * 180 / Math.PI) <= 100) {
                continue;
            }

            let nA = 100 * Math.sign(a) * Math.PI / 180;
            let diff = nA - a;
            let cos = Math.cos(diff);
            let sin = Math.sin(diff);
            let newX = nx * cos - ny * sin;
            let newY = nx * sin + ny * cos;
            newX *= nl;
            newY *= nl;
            points[next].x = points[i].x + newX;
            points[next].y = points[i].y + newY;
        }
        return points;
    }


    static hull(points: Vec2[]) {
        points.sort((a, b) => a.x == b.x ? a.y - b.y : a.x - b.x)
        let lower: Vec2[] = [];
        for (let i = 0, n = points.length; i < n; i++) {
            while (lower.length >= 2 && Vec2.cross(lower[lower.length - 2], lower[lower.length - 1], points[i]) <= 0) {
                lower.pop();
            }
            lower.push(points[i]);
        }

        let upper: Vec2[] = [];
        for (let i = points.length - 1; i >= 0; i--) {
            while (upper.length >= 2 && Vec2.cross(upper[upper.length - 2], upper[upper.length - 1], points[i]) <= 0) {
                upper.pop();
            }
            upper.push(points[i]);
        }
        upper.pop();
        lower.pop();
        return lower.concat(upper);
    }

    static stabilizePoints(points: Vec2[], iterations: number) {
        for (let i = 0; i < iterations; ++i) {
            points = this.widen(points, 40);
        }
        return points;
    }

    static widen(points: Vec2[], dst: number) {
        let dst2 = dst * dst;
        for (let i = 0, n = points.length; i < n; i++) {
            for (let j = i + 1, m = points.length; j < m; j++) {
                if (Math.pow(Vec2.distance(points[i], points[j]), 2) < dst2) {
                    console.log(" pushapart " + i + " / " + j + " // " + points[i] + " " + points[j])
                    let dx = points[j].x - points[i].x;
                    let dy = points[j].y - points[i].y;
                    let hl = Math.sqrt(dx * dx + dy * dy);
                    console.log(" dst=" + dst + ", hl=" + hl)
                    dx /= hl;
                    dy /= hl;
                    let difference = dst - hl;
                    dx *= difference;
                    dy *= difference;
                    console.log("  " + dx + ", " + dy)
                    points[j].x += dx;
                    points[j].y += dy;
                    points[i].x -= dx;
                    points[i].y -= dy;
                }
            }
        }
        return points;
    }

    static increaseCurves(points: Vec2[]) {
        let rSet: Array<Vec2> = new Array(points.length * 2)
        let disp = new Vec2(0, 0)
        let difficulty = 1
        let maxDisp = 20
        for (let i = 0, n = points.length; i < n; i++) {
            let dispLen = Math.pow(Math.random(), difficulty) * maxDisp
            disp.x = 0
            disp.y = 1
            Vec2.rotate(disp, 0.5, 0.5, Util.deg2rad(Math.random() * 360))
            disp.x *= dispLen
            disp.y *= dispLen
            rSet[i * 2] = points[i]
            rSet[i * 2 + 1] = new Vec2(points[i].x, points[i].y)
            let pointTemp = points[(i + 1) % points.length]
            rSet[i * 2 + 1].add(pointTemp).multiply(.5, .5).add(disp)
        }
        points = rSet
        points = this.stabilizePoints(points, 3)
        return points
    }

    static center(points: Vec2[]) {
        let minx = 1E20
        let maxx = -1E20
        let miny = 1E20
        let maxy = -1E20
        for (let i = 0, n = points.length; i < n; i++) {
            let p = points[i]
            minx = Math.min(minx, p.x)
            maxx = Math.max(maxx, p.x)
            miny = Math.min(miny, p.y)
            maxy = Math.max(maxy, p.y)
        }
        let cx = (maxx - minx) * .5
        let cy = (maxy - miny) * .5
        let t = new Vec2(minx + cx, miny + cy)
        for (let i = 0, n = points.length; i < n; i++) {
            let p = points[i]
            p.subtract(t)
        }
        return points
    }


}