




















import { StateType } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { Store } from "vuex";

import {
    AmbientLight,
    AxesHelper,
    BackSide,
    CameraHelper,
    Color,
    DirectionalLight,
    Fog,
    GridHelper,
    HemisphereLight,
    Material,
    Mesh,
    MeshLambertMaterial,
    PCFSoftShadowMap,
    PerspectiveCamera,
    PlaneGeometry,
    Raycaster,
    Scene,
    ShaderMaterial,
    SphereBufferGeometry,
    Vector2,
    WebGLRenderer,
} from "three";
import { OrbitControls } from "../lib/orbitcontrols";
import { TWEEN } from "../lib/tween";
import { Game } from "../game";

@Component({
    beforeRouteLeave(to, from, next) {
        const gameArea = GameArea.instance;
        if (gameArea) {
            const store = gameArea.$store as Store<StateType>;
            store.commit("gameStarted", false);
        }
        next();
    },
})
export default class GameArea extends Vue {
    static instance: GameArea;

    camera!: PerspectiveCamera;
    traceCamera!: PerspectiveCamera;
    scene!: Scene;
    sky!: Mesh;
    bottomPlane: Mesh;
    raycaster!: Raycaster;
    renderer!: WebGLRenderer;
    mountedFlag = false;
    orbitControls!: OrbitControls;
    game!: Game;
    canvas!: HTMLCanvasElement;
    lastMousedown!: Vector2;

    created() {
        GameArea.instance = this;
    }

    mounted() {
        this.renderer = new WebGLRenderer();
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(0xffffff, 1);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMapType = PCFSoftShadowMap;

        this.camera = this.initCamera();
        this.traceCamera = this.initTraceCamera();
        this.scene = this.initScene();
        this.raycaster = new Raycaster();

        this.mountedFlag = true;
        this.setRendererSize();
        this.canvas = this.renderer.domElement as HTMLCanvasElement;
        this.$el.appendChild(this.canvas);
        window.addEventListener("resize", this.onWindowResize, false);
        this.canvas.addEventListener(
            "mousedown",
            (ev) => {
                this.onMousedown(ev as MouseEvent);
            },
            false
        );
        this.canvas.addEventListener(
            "mouseup",
            (ev) => {
                this.onMouseup(ev as MouseEvent);
            },
            false
        );
        this.canvas.addEventListener(
            "mousemove",
            (ev) => {
                this.onMousemove(ev as MouseEvent);
            },
            false
        );
        this.addOrbitControls(); // must add after our event handlers
        this.animate();
        this.game = new Game(this.scene);
        this.game.init().then(() => {
            this.game.on("state", () => this.rehashGameState());
            this.rehashGameState();
        });
    }

    getGame() {
        return this.game;
    }

    rehashGameState() {}

    addOrbitControls(): void {
        if (this.orbitControls) {
            this.orbitControls.dispose();
        }
        this.orbitControls = new OrbitControls(
            this.camera,
            this.$el as HTMLElement
        );
        this.orbitControls.rotateSpeed = 1;
        this.orbitControls.zoomSpeed = 0.5;
        this.orbitControls.enableZoom = true;
        this.orbitControls.enablePan = true;
        this.orbitControls.enableRotate = true;
        this.orbitControls.enableKeys = true;
    }

    getDeviceWidth() {
        return (this.$el as HTMLElement).offsetWidth;
    }

    getDeviceHeight() {
        return (this.$el as HTMLElement).offsetHeight;
    }

    initCamera(): PerspectiveCamera {
        let width = this.getDeviceWidth();
        let height = this.getDeviceHeight();
        let camera = new PerspectiveCamera(
            75,
            (width + 0.0) / height,
            0.1,
            5000
        );
        camera.position.set(0, 450, 1000);
        return camera;
    }

    initTraceCamera(): PerspectiveCamera {
        let width = this.getDeviceWidth();
        let height = this.getDeviceHeight();
        let camera = new PerspectiveCamera(
            75,
            (width + 0.0) / height,
            0.1,
            5000
        );
        camera.position.set(0, 450, 1000);
        return camera;
    }

    initScene(): Scene {
        let scene = new Scene();
        scene.background = new Color(0xffffff);
        scene.background = new Color(0xeeeeee);
        scene.fog = new Fog(0xeeeeee, 1600, 3000);

        {
            const light1 = new AmbientLight(0xffffff, 4);
            light1.name = "ambient_light";
            this.camera.add(light1);
            scene.add(light1);

            const light2 = new DirectionalLight(0xffffff, 3);
            light2.position.set(0.5, 0, 0.866); // ~60º
            light2.name = "main_light";
            scene.add(light2);

            const light3 = new DirectionalLight(0xffffff, 3);
            light3.position.set(-0.5, 0, -0.866); // ~60º
            light3.name = "main_light";
            scene.add(light3);
        }
        {
            const light = new AmbientLight(0xffffff);
            // scene.add(light)
        }
        /*
        {
            let light = new HemisphereLight(0xffffff, 0x444444);
            light.position.set(0, 200, 0);
            scene.add(light);
        }
        */
        {
            let light = new DirectionalLight(0xffffff);
            light.name = "shadowLight";
            light.position.set(0, 350, 50);
            light.castShadow = true;
            let size = 500;
            light.shadow.camera.bottom = -size;
            light.shadow.camera.top = size;
            light.shadow.camera.left = -size;
            light.shadow.camera.right = size;
            light.shadow.camera.far = 500;
            light.shadowMapWidth = light.shadowMapHeight = 2048;
            //scene.add(light);

            /*
            let lightCameraHelper = new CameraHelper(light.shadow.camera);
            lightCameraHelper.name = "lightCameraHelper";
            lightCameraHelper.visible = false;
            scene.add(lightCameraHelper);
            */
            //console.log(light.toJSON());
        }

        let axisHelper = new AxesHelper(1500);
        axisHelper.name = "axesHelper";
        axisHelper.visible = false;
        scene.add(axisHelper);

        //let geometry = new THREE.BoxGeometry(1, 3, 1);
        //let material = new THREE.MeshBasicMaterial({ color: 0x30ff70 });
        //let cube = new THREE.Mesh(geometry, material);
        //scene.add(cube);

        // bottom plane0
        this.bottomPlane = new Mesh(
            new PlaneGeometry(5000, 5000),
            new MeshLambertMaterial({ color: 0x202020 }));
        this.bottomPlane.visible = false
        this.bottomPlane.name = "bottomPlane";
        this.bottomPlane.position.y = -6;
        this.bottomPlane.rotation.x = - Math.PI / 2;
        scene.add(this.bottomPlane);

        // shadow plane
        /*
        let shadowPlane = new THREE.Mesh(
            new THREE.PlaneGeometry(5000, 5000),
            new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false }));
        shadowPlane.name = "shadowPlane";
        shadowPlane.rotation.x = - Math.PI / 2;
        shadowPlane.receiveShadow = true;
        scene.add(shadowPlane);
        */

        // SKYDOME

        const vertexShader = document.getElementById("vertexShader").textContent;
        const fragmentShader = document.getElementById("fragmentShader").textContent;
        const uniforms = {
            topColor: { value: new Color(0x0077ff) },
            bottomColor: { value: new Color(0xffffff) },
            offset: { value: 33 },
            exponent: { value: 0.6 },
        };
        uniforms["topColor"].value.copy(new Color(0x4040ff));

        scene.fog.color.copy(uniforms["bottomColor"].value);

        const skyGeo = new SphereBufferGeometry(4000, 32, 15);
        const skyMat = new ShaderMaterial({
            uniforms: uniforms,
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: BackSide,
        });
        this.sky = new Mesh(skyGeo, skyMat);
        this.sky.visible = false
        scene.add(this.sky);

        // grid
        let grid = new GridHelper(2000, 100);
        grid.name = "grid";
        grid.position.y = -30;
        (grid.material as Material).opacity = 0.25;
        (grid.material as Material).transparent = true;
        //grid.material.opacity = 0.2;
        //grid.material.transparent = true;
        scene.add(grid);

        return scene;
    }

    @Watch("$store.state.gameStarted")
    gameStartedChanged() {
        const store = this.$store as Store<StateType>;
        if (store.state.gameStarted) {
            this.sky.visible = true
            this.bottomPlane.visible = true
        } else {
            this.sky.visible = false
            this.bottomPlane.visible = false
        }
    }


    onWindowResize() {
        let w = this.getDeviceWidth(),
            h = this.getDeviceHeight();
        this.setRendererSize();

        this.camera.aspect = w / h;
        this.camera.updateProjectionMatrix();

        this.traceCamera.aspect = w / h;
        this.traceCamera.updateProjectionMatrix();
    }

    setRendererSize() {
        if (!this.mountedFlag) return;
        let w = this.getDeviceWidth(),
            h = this.getDeviceHeight();
        this.renderer.setSize(w, h);
    }

    animate() {
        requestAnimationFrame(this.animate);
        this.step();
    }

    step() {
        TWEEN.update();
        const time = Date.now();
        const looptime = 50 * 1000;
        const t = (time % looptime) / looptime;
        if (this.game && this.game.getTrack()) {
            this.game.getTrack().applyToCamera(t, this.traceCamera);
        }
        const store = this.$store as Store<StateType>;
        this.renderer.render(
            this.scene,
            this.game && store.state.gameStarted
                ? this.traceCamera
                : this.camera
        );
    }

    // mouse interaction

    screen2viewport(e: MouseEvent): THREE.Vector2 {
        let width = this.getDeviceWidth();
        let height = this.getDeviceHeight();
        let cx = e.offsetX;
        let cy = e.offsetY;
        let x = (cx / width) * 2 - 1;
        let y = -(cy / height) * 2 + 1;
        return new Vector2(x, y);
    }

    onMousedown(event: MouseEvent) {
        let vp = this.screen2viewport(event);
        this.lastMousedown = new Vector2(vp.x, vp.y);
        // pick element
    }

    onMouseup(event: MouseEvent) {
        let vp = this.screen2viewport(event);
        let closeEnoughToClickLocation =
            this.lastMousedown && this.lastMousedown.distanceTo(vp) < 0.05;
        let yDistance = this.lastMousedown ? vp.y - this.lastMousedown.y : 0;
        if (closeEnoughToClickLocation || yDistance > 0.05) {
            //let card = this.pickCard(vp);
        }
        this.orbitControls.enabled = true;
    }

    onMousemove(event: MouseEvent) {
        let vp = this.screen2viewport(event);
    }
}
