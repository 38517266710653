import { BufferGeometry, CatmullRomCurve3, Curve, Float32BufferAttribute, Vector2, Vector3 } from "three";

export class RoadBufferGeometry extends BufferGeometry {

    path: CatmullRomCurve3
    tangents: Vector3[]
    normals: Vector3[]
    binormals: Vector3[]

    constructor(path: CatmullRomCurve3, tubularSegments = 64, radius = 1, radialSegments = 8, closed = false) {
        super();
        this.path = path
        this.type = 'RoadGeometry';
        const frames = (path as any).computeFrenetFrames(tubularSegments, closed);
		this.tangents = frames.tangents;
		this.normals = frames.normals;
		this.binormals = frames.binormals;

        // helper variables
        const vertex = new Vector3();
        const normal = new Vector3();
        const uv = new Vector2();
        let P = new Vector3();

        // buffer

        const vertices: number[] = [];
        const normals: number[] = [];
        const uvs: number[] = [];
        const indices: number[] = [];

        // create buffer data
        generateBufferData();

        if (false) {
            for (let i = 0, j = 0, n = indices.length; i < n; i++, j++) {
                let index = indices[i]
                let x = vertices[index * 3]
                let y = vertices[index * 3]
                let z = vertices[index * 3]
                let u = uvs[index * 2]
                let v = uvs[index * 2 + 1]
    
                console.log(`[${index}]: x=${x.toFixed(4)}, y=${y.toFixed(4)}, z=${z.toFixed(4)}, u=${u.toFixed(4)}, v=${v.toFixed(4)}, `)
                if (j == 2) {
                    console.log("-")
                    j = -1
                }
            }
        }


        this.setIndex(indices);
        this.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        this.setAttribute('normal', new Float32BufferAttribute(normals, 3));
        this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));

        // functions

        function generateBufferData() {

            for (let i = 0; i < tubularSegments; i++) {
                generateSegment(i);
            }

            // if the geometry is not closed, generate the last row of vertices and normals
            // at the regular position on the given path
            //
            // if the geometry is closed, duplicate the first row of vertices and normals (uvs will differ)
            generateSegment((closed === false) ? tubularSegments : 0);

            // uvs are generated in a separate function.
            // this makes it easy compute correct values for closed geometries
            generateUVs();

            // finally create faces
            generateIndices();
        }

        function generateSegment(i: number) {
            // we use getPointAt to sample evenly distributed points from the given path
            P = path.getPointAt(i / tubularSegments, P);

            // retrieve corresponding normal and binormal
            const N = frames.normals[i];
            const B = frames.binormals[i];

            // generate normals and vertices for the current segment
            for (let j = 0; j <= radialSegments; j++) {
                const v = j / radialSegments * Math.PI * 2 + Math.PI * 0.5;
                const sin = Math.sin(v);
                const cos = - Math.cos(v);

                // normal
                normal.x = (cos * N.x + sin * B.x);
                normal.y = (cos * N.y + sin * B.y);
                normal.z = (cos * N.z + sin * B.z);
                normal.normalize();
                normals.push(normal.x, normal.y, normal.z);

                // vertex
                vertex.x = P.x + radius * normal.x;
                vertex.y = P.y + radius * normal.y;
                vertex.z = P.z + radius * normal.z;
                vertices.push(vertex.x, vertex.y, vertex.z);
            }
        }

        function generateIndices() {
            for (let j = 1; j <= tubularSegments; j++) {
                for (let i = 1; i <= radialSegments; i++) {
                    const a = (radialSegments + 1) * (j - 1) + (i - 1);
                    const b = (radialSegments + 1) * j + (i - 1);
                    const c = (radialSegments + 1) * j + i;
                    const d = (radialSegments + 1) * (j - 1) + i;
                    // faces
                    indices.push(a, b, d);
                    indices.push(b, c, d);
                }
            }

        }

        function generateUVs() {
            for (let i = 0; i <= tubularSegments; i++) {
                for (let j = 0; j <= radialSegments; j++) {
                    uv.x = i / tubularSegments;
                    uv.y = j / radialSegments;

                    uv.x = i % 2 == 0 ? 0 : 1;
                    uv.y = j % 2 == 0 ? 0 : 1;
                    
                    uvs.push(uv.x, uv.y);
                }
            }
        }

    }
}


