
export class Vec2 {
    x: number
    y: number

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
    }

    length() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    add(v: Vec2) {
        this.x += v.x
        this.y += v.y
        return this
    }

    subtract(v: Vec2) {
        this.x -= v.x
        this.y -= v.y
        return this
    }

    multiply(dx: number, dy: number) {
        this.x *= dx
        this.y *= dy
        return this
    }

    toString() {
        return `Vec2(${this.x.toFixed(3)}, ${this.y.toFixed(3)})`
    }

    static rotate(a: Vec2, x: number, y: number, angle: number) {
        let distance = Math.sqrt(((x - a.x) * (x - a.x)) + ((y - a.y) * (y - a.y)));
        let t = angle + Math.atan2(a.y - y, a.x - x);
        a.x = x + distance * Math.cos(t);
        a.y = y + distance * Math.sin(t);
        return a;
    }

    static distance(a: Vec2, b: Vec2) {
        return new Vec2(a.x - b.x, a.y - b.y).length()
    }

    static cross(o: Vec2, a: Vec2, b: Vec2) {
        return (a.x - o.x) * (b.y - o.y) - (a.y - o.y) * (b.x - o.x)
    }
}